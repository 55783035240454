@import "@/styles/theme/main.scss";
.image,
.name,
.provider {
  @include skeleton($secondary: true);
}

.image {
  width: 50px;
  height: 50px;
}

.name {
  width: 80px;
  height: 16px;
}

.provider {
  width: 50px;
  height: 14px;
}
