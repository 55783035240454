@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  height: $box-height;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 16px;
  border-radius: $border-radius;
  transition: all 0.25s ease-in-out;
  font-size: 14px;
  font-weight: 700;
  position: relative;
  background-color: $card-color;
  color: $text-primary;

  &:hover {
    background-color: $background-default;
    @include lighen(50%);
  }
}
