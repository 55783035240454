@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  max-width: 450px;
  border-radius: $border-radius;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
