@import "@/styles/theme/main.scss";
.container {
  min-width: 170px;
  display: flex;
  flex-direction: column;
  background-color: $card-color;
  border-radius: $border-radius;
  overflow: hidden;
  transition: transform 0.15s ease-out 0s;

  &:hover {
    & .container__image-container__info,
    & .container__image-container__info::before {
      opacity: 1;
    }
  }

  @include media-tablet {
    min-width: 100px;

    & .container__image-container__info {
      opacity: 0;
    }
    & .container__image-container__info::before {
      opacity: 0;
    }
  }

  &__image-container {
    width: 100%;
    height: 100%;
    max-height: 170px;
    position: relative;
    overflow: hidden;

    @include media-tablet {
      max-height: 100px;
    }

    &__image {
      width: 100%;
      height: 100%;
      aspect-ratio: 1;
      object-fit: cover !important;

      @include media-tablet {
        min-width: 100px;
        min-height: 100px;
      }
    }

    &__info {
      display: flex;
      position: absolute;
      inset: 0;
      padding: 16px;
      display: flex;
      align-items: start;
      justify-content: space-between;
      flex-direction: column;
      opacity: 0;
      transition: all 0.3s;

      &::before {
        content: '';
        width: 100%;
        height: 100%;
        background: linear-gradient(
          to bottom,
          $background-default -10%,
          transparent 100%
        );
        position: absolute;
        inset: 0;
        transition: all 0.3s;
        z-index: 0;
        opacity: 0;
      }

      &__title {
        font-size: 12px;
        font-weight: 700;
        color: $text-primary;
        z-index: 1;
      }

      &__play__button {
        width: 40px;
        height: 40px;
        margin-inline: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.25s ease-in-out;
        cursor: pointer;
        font-size: 18px;
        z-index: 2;
        border-radius: $border-radius;
        @include usePrimaryBg;

        &:hover {
          @include useSecondaryBg;
        }
      }
    }
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 8px;

    &__row {
      width: 100%;
      display: grid;
      grid-template-columns: auto 20px;
      gap: 8px;
      align-items: center;

      &__title {
        width: 100%;
        font-size: 14px;
        font-weight: 700;
        color: $text-primary;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 1rem;

        @include media-tablet {
          font-size: 12px;
        }
      }

      &__icon {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        font-size: 14px;
        cursor: pointer;
        color: $text-secondary;
        transition: color 0.3s;

        &:hover {
          color: $text-primary;
        }
      }
    }

    &__description {
      font-size: 12px;
      font-weight: 600;
      color: $text-secondary;
      cursor: pointer;
      transition: all 0.3s;

      &:hover {
        color: $text-primary;
      }
    }

    &__amount {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 8px;

      &__coin {
        &__image {
          width: 15px;
          height: 15px;
          border-radius: 50%;
        }

        &__amount {
          font-size: 14px;
          font-weight: 700;
          color: $success-color;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          & i {
            margin-right: 4px;
          }

          @include media-tablet {
            font-size: 10px;
          }
        }
      }
    }
  }

  &__hide-mobile {
    display: flex;

    @include media-phone {
      display: none;
    }
  }
}
