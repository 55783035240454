@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  position: relative;
  height: $box-height;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  border-radius: $border-radius;
  padding-left: 16px;
  padding-right: 16px;
  color: $text-primary;
  background-color: $background-default;
  border: 1px solid $background-default;
  cursor: pointer;
  transition: border 0.25s ease-in-out;

  &:hover,
  &:focus {
    border: 1px solid $primary-color;
  }

  &__row {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;

    &--small {
      gap: 4px;
    }

    &__name {
      font-weight: 400;
      font-size: 13px;
      color: $text-primary;

      &--secondary {
        color: $text-secondary;
      }
    }

    &__image {
      width: 28px;
      height: 28px;
      aspect-ratio: 28 / 28;
      border-radius: $border-radius;
    }
  }

  & i {
    position: absolute !important;
    right: 16px;
    top: 50%;
    font-size: 14px;
    pointer-events: none;
    transform: translateY(-50%);
    transition: ease 0.3s;
    color: $text-primary;
  }
}
