@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  height: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  &--variant {
    margin-top: 24px;
    gap: 32px;
  }

  &__column {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  &__row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;

    &__link {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 12px;
      line-height: 14px;
      font-weight: 400;
      cursor: pointer;
      color: $text-primary !important;
      transition: all 0.25s ease-in-out;
      &--variant {
        margin-top: 8px;
      }

      &:hover,
      &:focus {
        text-decoration: underline !important;
      }
    }
  }
  &__binding {
    line-height: 16px;
    font-size: 12px;
    font-weight: 600;
    color: $text-disabled;
    text-transform: capitalize;
  }

  &__text {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    text-align: start;

    &__highlight {
      color: $primary-color !important;
      transition: all 0.25s ease-in-out;
      cursor: pointer;

      &:hover,
      &:focus {
        text-decoration: underline !important;
        color: $secondary-color !important;
      }
    }

    @include media-tablet {
      font-size: 12px;
    }
  }
}
