@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  display: grid;
  grid-template-columns: auto 40px;
  gap: 8px;
  background-color: $background-paper;
  border-radius: $border-radius;
  align-items: center;
  padding: 16px;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background-color: $card-color;

    .container__wrapper__name {
      color: $text-primary;
    }
  }

  &--loading {
    pointer-events: none;
  }

  &__column {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &__wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;

    &__image {
      width: 50px;
      height: 50px;
      border-radius: $border-radius;
    }

    &__name {
      width: 100%;
      overflow: hidden;
      text-wrap: nowrap;
      text-overflow: ellipsis;
      font-size: 14px;
      font-weight: 500;
      color: $text-secondary;
      transition: color 0.25s ease-in-out;
    }

    &__provider {
      width: 100%;
      overflow: hidden;
      text-wrap: nowrap;
      text-overflow: ellipsis;
      font-size: 12px;
      font-weight: 500;
      color: $text-secondary;
    }
  }

  &--isSelected {
    background-color: $card-color;

    .container__wrapper__name {
      color: $text-primary;
    }
  }
}
