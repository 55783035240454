@import "@/styles/theme/main.scss";
.text,
.chevron {
  @include skeleton();
}

.text {
  width: 80px;
  height: 16px;
}

.chevron {
  width: 18px;
  height: 18px;
  border-radius: 50%;
}
