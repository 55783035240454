@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  &__lottie {
    width: 200px;
  }

  &__title {
    font-size: 16px;
    font-weight: 600;
    color: $text-primary;
  }

  &__description {
    font-size: 14px;
    font-weight: 500;
    color: $text-secondary;
    text-align: center;
    max-width: 300px;
    margin: 0 auto;
  }
}
