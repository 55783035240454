@import "@/styles/theme/main.scss";
.name,
.icon,
.text,
.coin {
  @include skeleton($secondary: true);
}

.image {
  @include skeleton();
  width: 100%;
  height: 100%;
  aspect-ratio: 1;
}

.name {
  width: 100%;
  height: 16px;
}

.icon {
  width: 14px;
  height: 14px;
  border-radius: 50%;
}

.text {
  width: 50%;
  height: 14px;
}

.coin {
  width: 15px;
  min-width: 15px;
  height: 15px;
  border-radius: 50%;
}
