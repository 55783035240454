@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  &--align-center {
    align-items: center;
  }

  &__label {
    font-size: 14px;
    font-weight: 700;
    color: $text-secondary;

    &--primary {
      color: $text-primary;
    }

    &__skeleton,
    &__skeleton--secondary {
      width: 120px;
      height: 16px;
    }

    &__skeleton {
      @include skeleton();
    }

    &__skeleton--secondary {
      @include skeleton($secondary: true);
    }
  }

  &__description {
    font-size: 12px;
    font-weight: 700;
    color: $text-secondary;
    @include media-tablet {
      font-size: 12px;
    }

    &__skeleton,
    &__skeleton--secondary {
      width: 100%;
      height: 16px;
    }

    &__skeleton {
      @include skeleton();
    }

    &__skeleton--secondary {
      @include skeleton($secondary: true);
    }
  }
}
