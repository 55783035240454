@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: $background-paper;
  position: absolute;
  z-index: 4;
  top: 0;
  pointer-events: all !important;
  left: 0;

  &__button {
    all: unset;
    box-sizing: border-box;
    width: max-content;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    padding: 8px;
    border-radius: $border-radius;
    background-color: $card-color;
    transition: all 0.25s ease-in-out;
    position: relative;
    z-index: 4;

    &:hover {
      @include darken(20%);
    }
  }
}
