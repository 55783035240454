@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;

  &--end {
    justify-content: flex-end;
  }

  &--positive {
    color: $success-color;
  }

  &--negative {
    color: $error-color;
  }

  &__coin {
    width: 14px;
    border-radius: 50%;
  }
}
