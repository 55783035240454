@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  cursor: pointer;

  & i {
    transition: color 0.25s ease-in-out;
    pointer-events: none;
  }

  & input {
    cursor: pointer !important;
    color: $text-secondary !important;

    &:read-only {
      cursor: pointer !important;
    }
  }

  &:hover {
    & input,
    & i {
      color: $text-primary !important;
    }
  }
}
