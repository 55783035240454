@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  background-color: $card-color;
  padding: 16px;
  border-radius: $border-radius;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
